/* week0 */
.week0 {
  height: auto;
  width: 100%;
  border-radius: 50%;
  background-color: transparent;
  transform: translate(0, 0);
}

.week0:hover:before {
  animation: ripple 1s linear infinite;
}

.week0:hover:after {
  animation: ripple 1s linear .5s infinite;
}

.week0:before,
.week0:after {
  content: "";
  display: block;
  position: absolute;
  opacity: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: .35rem solid #b6634c;
  border-radius: 50%;
}

/* week2 */
.week2 {
  height: auto;
  width: 100%;
  border-radius: 50%;
  background-color: transparent;
  transform: translate(0, 0);
}

.week2:hover:before {
  animation: ripple 1s linear 1;
}

.week2:hover:after {
  animation: ripple 1s linear .5s 1;
}

.week2:before,
.week2:after {
  content: "";
  display: block;
  position: absolute;
  opacity: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: .35rem solid #607252;
  border-radius: 50%;
}

/* week4 */
.week4 {
  height: auto;
  width: 100%;
  border-radius: 50%;
  background-color: transparent;
  transform: translate(0, 0);
}

.week4:hover:before {
  animation: ripple 1s linear 1;
}

.week4:hover:after {
  animation: ripple 1s linear .5s 1;
}

.week4:before,
.week4:after {
  content: "";
  display: block;
  position: absolute;
  opacity: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: .35rem solid #606862;
  border-radius: 50%;
}

/* week8 */
.week8 {
  height: auto;
  width: 100%;
  border-radius: 50%;
  background-color: transparent;
  transform: translate(0, 0);
}

.week8:hover:before {
  animation: ripple 1s linear 1;
}

.week8:hover:after {
  animation: ripple 1s linear .5s 1;
}

.week8:before,
.week8:after {
  content: "";
  display: block;
  position: absolute;
  opacity: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: .35rem solid #97b5b9;
  border-radius: 50%;
}

/* week12 */
.week12 {
  height: auto;
  width: 100%;
  border-radius: 50%;
  background-color: transparent;
  transform: translate(0, 0);
}

.week12:hover:before {
  animation: ripple 1s linear 1;
}

.week12:hover:after {
  animation: ripple 1s linear .5s 1;
}

.week12:before,
.week12:after {
  content: "";
  display: block;
  position: absolute;
  opacity: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: .35rem solid #b6634c;
  border-radius: 50%;
}

/* week16 */
.week16 {
  height: auto;
  width: 100%;
  border-radius: 50%;
  background-color: transparent;
  transform: translate(0, 0);
}

.week16:hover:before {
  animation: ripple 1s linear 1;
}

.week16:hover:after {
  animation: ripple 1s linear .5s 1;
}

.week16:before,
.week16:after {
  content: "";
  display: block;
  position: absolute;
  opacity: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: .35rem solid #606862;
  border-radius: 50%;
}

/* week20 */
.week20 {
  height: auto;
  width: 100%;
  border-radius: 50%;
  background-color: transparent;
  transform: translate(0, 0);
}

.week20:hover:before {
  animation: ripple 1s linear 1;
}

.week20:hover:after {
  animation: ripple 1s linear .5s 1;
}

.week20:before,
.week20:after {
  content: "";
  display: block;
  position: absolute;
  opacity: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: .35rem solid #606862;
  border-radius: 50%;
}

/* week24 */
.week24 {
  height: auto;
  width: 100%;
  border-radius: 50%;
  background-color: transparent;
  transform: translate(0, 0);
}

.week24:hover:before {
  animation: ripple 1s linear 1;
}

.week24:hover:after {
  animation: ripple 1s linear .5s 1;
}

.week24:before,
.week24:after {
  content: "";
  display: block;
  position: absolute;
  opacity: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: .35rem solid #97b5b9;
  border-radius: 50%;
}

/* week32 */
.week32 {
  height: auto;
  width: 100%;
  border-radius: 50%;
  background-color: transparent;
  transform: translate(0, 0);
}

.week32:hover:before {
  animation: ripple 1s linear 1;
}

.week32:hover:after {
  animation: ripple 1s linear .5s 1;
}

.week32:before,
.week32:after {
  content: "";
  display: block;
  position: absolute;
  opacity: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: .35rem solid #b6634c;
  border-radius: 50%;
}

/* week40 */
.week40 {
  height: auto;
  width: 100%;
  border-radius: 50%;
  background-color: transparent;
  transform: translate(0, 0);
}

.week40:hover:before {
  animation: ripple 1s linear 1;
}

.week40:hover:after {
  animation: ripple 1s linear .5s 1;
}

.week40:before,
.week40:after {
  content: "";
  display: block;
  position: absolute;
  opacity: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: .35rem solid #607252;
  border-radius: 50%;
}

/* week48 */
.week48 {
  height: auto;
  width: 100%;
  border-radius: 50%;
  background-color: transparent;
  transform: translate(0, 0);
}

.week48:hover:before {
  animation: ripple 1s linear 1;
}

.week48:hover:after {
  animation: ripple 1s linear .5s 1;
}

.week48:before,
.week48:after {
  content: "";
  display: block;
  position: absolute;
  opacity: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: .35rem solid #606862;
  border-radius: 50%;
}

/* week52 */
.week52 {
  height: auto;
  width: 100%;
  border-radius: 50%;
  background-color: transparent;
  transform: translate(0, 0);
}

.week52:hover:before {
  animation: ripple 1s linear 1;
}

.week52:hover:after {
  animation: ripple 1s linear .5s 1;
}

.week52:before,
.week52:after {
  content: "";
  display: block;
  position: absolute;
  opacity: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: .35rem solid #97b5b9;
  border-radius: 50%;
}

/* followup1 */
.followup1 {
  height: auto;
  width: 100%;
  border-radius: 50%;
  background-color: transparent;
  transform: translate(0, 0);
}

.followup1:hover:before {
  animation: ripple 1s linear 1;
}

.followup1:hover:after {
  animation: ripple 1s linear .5s 1;
}

.followup1:before,
.followup1:after {
  content: "";
  display: block;
  position: absolute;
  opacity: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: .35rem solid #b6634c;
  border-radius: 50%;
}

/* followup2 */
.followup2 {
  height: auto;
  width: 100%;
  border-radius: 50%;
  background-color: transparent;
  transform: translate(0, 0);
}

.followup2:hover:before {
  animation: ripple 1s linear 1;
}

.followup2:hover:after {
  animation: ripple 1s linear .5s 1;
}

.followup2:before,
.followup2:after {
  content: "";
  display: block;
  position: absolute;
  opacity: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: .35rem solid #607252;
  border-radius: 50%;
}

@keyframes ripple {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
    opacity: 1;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
